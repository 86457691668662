import React from 'react';
import { Content } from './content.layout';
import { Header } from './header.layout';
// import { Footer } from './footer.layout';
const Layout = ({children}) => {
    return(
    	<div class="container">
    		<Header/>
    		<Content>
				{children}
			</Content>
			{/* <Footer/> */}
    	</div>
    )
}

export default Layout;