import React from 'react';

export const Content = ({children}) => {
    return(
        <div>
            <div id="primary" class="content-area">
                {children}
            </div>
        </div>
    )
}

        