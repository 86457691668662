import React, {Suspense} from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import MainRuter from './routers/index'

function App() {
    return (
        <div>
            <Router>
                <Suspense fallback={<div>Sedang memuat...</div>}>
                    {/* <Layout/> */}
                    <MainRuter/>
                </Suspense>
            </Router>
        </div>
    );
}

export default App;
