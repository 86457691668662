import React, {useEffect, useState } from 'react';
import { useParams } from "react-router";
import { Link, Redirect } from "react-router-dom";

const Order = () => {
    let { storeCode, } = useParams();
    const [dataSelect, setDataSelect] = useState();
    const [dataSelectArray, setDataSelectArray] = useState();
    const [keranjang, setDataKeranjang] = useState();
    const [nama, setNama] = useState('');
    const [hp, setHp] = useState('');
    const [alamat, setAlamat] = useState('');
    const [catatan, setCatatan] = useState('');
    const [hpToko, setHptoko] = useState('');
    const [reload, setReload] = useState('');

    useEffect(()=>{
        (async function getValueJumlah(){
            let dataGet = await valueJumlah();
            if(dataGet){
                setDataSelect(JSON.parse([dataGet]));
            }
        }());
        (async function getValueData(){
            let dataGets = await valueDataArray();
            if(dataGets){
                setDataSelectArray(JSON.parse(dataGets));
            }
        }());
        (async function getValueKeranjang(){
            let dataKeranjang = await valueKeranjang();
            if(dataKeranjang){
                setDataKeranjang(JSON.parse(dataKeranjang));
            }
        }());
        getNoHp();
    },[]);

    const getNoHp=()=>{
        let url = "https://garudakasir.garudacyber.co.id/v1.0/barang/toko?id="+storeCode;
        let opts = {
            method: 'GET',      
            headers: {'Content-Type':'application/json', 'Accept':'application/json'}
        };
        fetch(url, opts).then(function(response){
            return response.json();
        }).then(function (body) {
            if(body.data.hp.charAt(0)==0){
                let no_hp = "62"+body.data.hp.substring(1);
                setHptoko(no_hp);
            }else{
                setHptoko(body.data.hp);
            }
        });
    }


    const valueJumlah=async()=>{
        return await window.sessionStorage.getItem('dataStorager');
    }

    const valueDataArray=async()=>{
        return await window.sessionStorage.getItem('dataStoragerArray');
    }

    const valueKeranjang=async()=>{
        return await window.sessionStorage.getItem('keranjang');
    }

    const chatWa=()=>{
        let urlWa = `https://api.whatsapp.com/send?phone=`;
        let nohp = hpToko;
        let biodata = `&text=Nama%20:%20:${nama}%0ANo%20Hp%20:%20${hp}%0AAlamat%20:%20${alamat}%0ACatatan%20:%20${catatan}%0A%0A`;
        let message = "";
        let no = 1;
        let totalAkhir = 0;
        for(let ind in dataSelectArray){
            let total = dataSelectArray[ind].harga*dataSelect[dataSelectArray[ind].id];
            totalAkhir = totalAkhir+total; 
            message += `--------%0A${dataSelectArray[ind].nama}%0A${dataSelectArray[ind].harga}%20X%20${dataSelect[dataSelectArray[ind].id]}%20=%20${total}%0A`;
            no++
        }
        let totalBelanja = `%0A*Total%20Belanja*%0A*${totalAkhir}*`
        let catatanKaki = `%0A%0A*Nominal tersebut tersebut belum termasuk biaya pengiriman`
        window.open(urlWa+nohp+biodata+message+totalBelanja+catatanKaki);
        window.sessionStorage.removeItem('dataStorager');
        window.sessionStorage.removeItem('dataStoragerArray');
        window.sessionStorage.removeItem('keranjang');
        setReload(storeCode);
    }
    
    return(
        <div className="container" >
            <div className="cart-inner" style={{backgroundColor:'#fff'}}>
                <div className="cart-content" >
                    <div className="wrapper-cart custom-shadow" style={{backgroundColor:'#fff',  padding:16 }}>
                        <div className="cart-content-items" style={{backgroundColor:'#fff'}}>
                            <div className="cart-content-items-head">
                                <div className="cart-content-form-head">
                                    <Link className="btn btn-primary" to={"/"+storeCode}><span style={{ color:'#fff' }}>Edit pesanan</span></Link>
                                    <div className="cart-content-form-head-title" style={{marginTop:24}}>
                                        <strong>List Produk</strong>
                                    </div>
                                    
                                </div>
                                <div className="cart-content-items-list">
                                    
                                    {
                                        dataSelectArray&&dataSelectArray.map((item)=>
                                            <div className="productcart">
                                                <div className="content">
                                                    <div className="image">
                                                        <img src={`https://garudakasir.garudacyber.co.id/image/${item.idtoko}/${item.gambar}`}/>
                                                    </div>

                                                    <div className="detail">
                                                        <h3>{item.nama}</h3>
                                                        <div className="pricing">
                                                            <span className="price">Rp {item.harga} X {dataSelect&&dataSelect[item.id]}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        )
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper-cart custom-shadow" style={{backgroundColor:'#fff',  padding:16 }}>
                        <div className="cart-content-items" style={{backgroundColor:'#fff'}}>
                            <div className="cart-content-items-head">
                                <div className="cart-content-form-head">
                                    <div className="cart-content-form-head-title">
                                        <strong>Total belanja</strong>
                                    </div>
                                    
                                </div>
                                <div className="cart-content-items-list">
                                    { keranjang&&keranjang }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper-cart custom-shadow" style={{backgroundColor:'#fff',  padding:16 }}>
                        <div className="cart-content-form">
                            <div className="cart-content-form-head" style={{marginBottom:8}}>
                                <div className="cart-content-form-head-title">
                                    <strong>Data Pemesan</strong>
                                </div>
                            </div>
                            <div className="cart-content-form-list">
                                <form>
                                    <div className="form-group">
                                        <input onChange={(event)=>{setNama(event.target.value)}} type="text" value={nama} className="form-control" id="nama" aria-describedby="nama" placeholder="Nama..."/>
                                    </div>
                                    <div className="form-group">
                                        <input onChange={(event)=>{setHp(event.target.value)}} type="text" value={hp} className="form-control" id="hp" placeholder="No hp..."/>
                                    </div>
                                    <div className="form-group">
                                        <textarea rows="4" onChange={(event)=>{setCatatan(event.target.value)}} type="text" value={catatan} className="form-control" id="Alamat" placeholder="Catatan khusus mengenai produk yang anda pesan"/>
                                    </div>
                                    <div className="form-group">
                                        <textarea rows="4" onChange={(event)=>{setAlamat(event.target.value)}} type="text" value={alamat} className="form-control" id="Alamat" placeholder="Alamat Lengkap..."/>
                                    </div>
                                    <button type="button" onClick={()=>{chatWa()}} className="btn btn-primary">Pesan</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { reload && (<Redirect to={'/'+reload}/>) }
        </div>
    )
}

export default Order;