import {EventEmitter} from 'events'
import dispatcher from './../utils/flux/index.flux'
import { callApiGet } from './../services/axiosCustom';
import * as TYPES from './../actions/index';

class Home extends EventEmitter {
    
    responseApi ;
    constructor(){
        super()
        this.responseApi=[]
    } 

    isResponseData = (data) =>{
        this.responseApi=data;
    }

    isGetData = () => {
        return this.responseApi;
    }

    getDataHome=async(subUrl, query)=>{
        let url = '', status=false;
        if(query){
            url =`/barang/barang-mini-toko-online?toko=${subUrl}&query=${query}`;
        }else{
            url =`/barang/barang-mini-toko-online?toko=${subUrl}`;
        }
        let response = await callApiGet(url);
        if(response && response.status==200){
            status=true;
            this.isResponseData(response.data);
        }
        this.emit(TYPES.GET_DATA_HOME, {status})
    }

    _addListener(callback){
        this.on(TYPES.GET_DATA_HOME, ({status})=>{
            callback(status)
        });
    }

    _removeListener(callback){
        this.removeListener(TYPES.GET_DATA_HOME, callback)
    }

    //handle action
    handleActions=async(action)=>{
        switch(action.type){
            case TYPES.GET_DATA_HOME:
                let {url, query} = action;
                await this.getDataHome(url, query);
                break
        }
    }
}

const home= new Home();
dispatcher.register(home.handleActions.bind(home))
export default home;