import React from 'react';
import {
    Switch,
    Route
} from "react-router-dom";
import Order from './../views/order/index.order.views';
import Error from './../views/error/index.view.error';
import RouterLayout from './layout.router';
import MainContent from './../views/main-content/index.view'

const MainRuter = () => {
    return(
        <Switch>
            <RouterLayout exact path={"/:storeCode/order"}>
                <Order/>
            </RouterLayout>
            <RouterLayout exact path="/:storeCode/:cari?" >
                <MainContent/>
            </RouterLayout>
            <RouterLayout exact path="/:storeCode?" >
                <MainContent/>
            </RouterLayout>
            <Route exact path="*">
                <Error/>
            </Route>
        </Switch>
    )
}

export default MainRuter;