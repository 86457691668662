import axios from 'axios'
axios.defaults.baseURL = 'https://garudakasir.garudacyber.co.id/v1.0';
axios.defaults.headers.common['Content-Type']=`multipart/form-data`;

export const callApiPost=async(url, data)=>{
    let response;
    try {
        response = await axios({
            method: 'POST',
            url:url,
            data:data
        });
        if(response && response.data){
            return response.data
        }
        else{
            return response
        }
    }
    catch(err){   
        if (err && err.response) {
            const axiosError = err
            let {status}=axiosError.response.data;
            if(status==401){
                console.log('error')
            }
            return axiosError.response.data;
        }
        return null
        // throw err;
    }
}

export const callApiGet=async(url, params)=>{
    let response
    try{
        response =await axios.get(url, {
            params: params
        })
        if(response && response.data){
            return response.data
        }
        else{
            return response
        }
    }
    catch(err){   
        if (err && err.response) {
          const axiosError = err
          let {status}=axiosError.response.data;
          if(status==401){
              console.log('error')
          }
          return axiosError.response.data;
        }
        return null
        // throw err;
    }
}