import React from 'react';

export const Header = () => {
    
    return(
        <header>
            <div class="site-header">
                <div class="wrapper">
                    <div class="headerbox">
                        <div class="logo-img" >
                            <a href="https://kasir.garudacyber.co.id/" >
                                <img class="lazy" style={{backgroundColor:'#fff', borderRadius:100 }} data-src="https://garudakasir.garudacyber.co.id/slider/logo.png" alt="Garuda Kasir" width="auto" height="auto" />
                            </a>
                        </div>
                        <div class="searchbox">
                            <form>
                                <input type="search" name="cari" placeholder="Search" />
                                <button type="button">
                                    <i class="lni lni-search"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <nav id="navigation" class="navigation">
                        <i id="nav-menu-toggle" class="lni lni-grid-alt"></i>
                        <div id="nav-menu" class="nav-menu">
                            <ul id="menu-header" class="menu-header">
                                <li id="menu-item-11" class="menu-item menu-item-type-post_type menu-item-object-page current_page_parent menu-item-11"><a href="https://kasir.garudacyber.co.id/">Beranda</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}