import React from 'react';

const Slider = () => {
    return(
        <div className="index">
            <div className="wrapper">
                <div className="slider">
                    <div className="slide">
                        <img className="tns-lazy-img" data-src="https://garudakasir.garudacyber.co.id/slider/slider-1.jpeg" />
                    </div>
                    <div className="slide">
                        <img className="tns-lazy-img" data-src="https://garudakasir.garudacyber.co.id/slider/slider-2.jpeg" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slider;