import React from 'react';
import { Route, Router } from "react-router-dom";
import Layout from './../components/layouts/index';

const RouterLayout = (props) => {
    let { children } = props;
    return(
        <div>
            <Route {...props}>
                <Layout>
                    { children }
                </Layout>
            </Route>
        </div>
    )
}

export default RouterLayout;