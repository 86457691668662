import React from 'react';
import Categori from './../categori/views.categori.index';
import Slider from './../slider/view.slider.index';
import Item from './../item/views.item.index';

const MainContent=()=>{
    return(
        <>
        <Slider/>
        <div class="index">
            <div class="wrapper">
                {/* <Categori/> */}
                <Item/>
            </div>
        </div>
        </>
    )
}

export default MainContent;