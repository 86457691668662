import React, { useEffect, useState } from 'react';
import Home from './../../models/home.model';
import { sendData as setDATA2} from './../../actions/home.action';
import { useParams, useLocation } from "react-router";
import queryString from 'query-string';
import { Link } from "react-router-dom";

let dataTes = {};
let dataArray = [];
let page = 0;
let tmpData =[]

const Item = () => {
    let { storeCode, } = useParams();
    let link = storeCode+'/order';
    let location = useLocation();
    const parsed = queryString.parse(location.search);
    const [data, setData] = useState();
    const [dataelect, setdataelect] = useState({});
    const [dataKeranjang, setDataKeranjang] = useState();

    const _sendData=(storeCode)=>{
        setDATA2(storeCode, parsed.cari||null);
    }

    const afterGetData=async (status)=> {
        if(status){
            if(page>0){
                let tampungData = await Home.isGetData();
                if(tampungData){
                    await Array.prototype.push.apply(tmpData,tampungData);
                    setData([...tmpData]);
                }
            }else{
                setData(Home.isGetData());
            }
            
        }
    }

    const valueJumlah=async()=>{
        return await window.sessionStorage.getItem('dataStorager');
    }

    const valueKeranjang=async()=>{
        return await window.sessionStorage.getItem('keranjang');
    }

    const addSelect=async(id)=>{
        let dataTampung = {};
        if(dataTes[id]===0||dataTes[id]===undefined){
            dataTampung[id] = 1;
        }else if(dataTes[id]>0){
            dataTampung[id] = dataTes[id]+1;
        }
        dataTes = {...dataTes, ...dataTampung};
        let hasil = 0;
        for(let val of Object.keys(dataTes)){
            if(data.find(({ id_barang }) => id_barang === val )){
                let dataFind = data.find(({ id_barang }) => id_barang === val );
                hasil=hasil+(dataTes[val]*dataFind.id_merek);
                if (!dataArray.find(o => o.id === val)){
                    dataArray.push({
                        id : id,
                        harga : dataFind.id_merek,
                        nama : dataFind.nama_barang,
                        gambar : dataFind.gambar,
                        idtoko : dataFind.idtoko
                    })
                    window.sessionStorage.setItem('dataStoragerArray', JSON.stringify(dataArray));
                }else{
                }
            }
        }
        setDataKeranjang(hasil)
        window.sessionStorage.setItem('keranjang', JSON.stringify(hasil));
        setdataelect(dataTes);
        window.sessionStorage.setItem('dataStorager', JSON.stringify(dataTes));
    }


    const minSelect=(id)=>{
        let dataTampung = {};
        if(dataTes[id]==0||dataTes[id]==undefined){
            dataTampung[id] = 0;
        }else if(dataTes[id]>0){
            dataTampung[id] = dataTes[id]-1;
        }
        dataTes = {...dataTes, ...dataTampung};
        let hasil = 0;
        for(let val of Object.keys(dataTes)){
            if(data.find(({ id_barang }) => id_barang === val )){
                let dataFind = data.find(({ id_barang }) => id_barang === val );
                hasil=hasil+(dataTes[val]*dataFind.id_merek);
                if (!dataArray.find(o => o.id === val)){
                    dataArray.push({
                        id : id,
                        harga : dataFind.id_merek,
                        nama : dataFind.nama_barang,
                        gambar : dataFind.gambar,
                        idtoko : dataFind.idtoko
                    })
                    window.sessionStorage.setItem('dataStoragerArray', JSON.stringify(dataArray));
                }else{
                }
            }
        }
        setDataKeranjang(hasil)
        window.sessionStorage.setItem('keranjang', JSON.stringify(hasil));
        setdataelect(dataTes);
        window.sessionStorage.setItem('dataStorager', JSON.stringify(dataTes));
    }

    useEffect(() => {
        Home._addListener(afterGetData);
        (async function getValueJumlah(){
            let dataGet = await valueJumlah();
            if(dataGet){
                setdataelect(JSON.parse([dataGet]));
                dataTes = JSON.parse([dataGet]);
            }
        }());

        (async function getKeranjang(){
            let dataKeranjang = await valueKeranjang();
            if(dataKeranjang){
                setDataKeranjang(JSON.parse([dataKeranjang]));
            }
        }());

        _sendData(storeCode);
        //return () => Home._removeListener(afterGetData);
        
    }, []);

    const loadMore=()=>{
        page++;
        tmpData=data;
        _sendData(storeCode+"&page="+page);
    }

    return(
        <main id="main" className="query">
            <div className="wrapper products-list">
                <div className="products-list-title">
                    <h2>Daftar Produk</h2>
                </div>
                
                <div className="products clear">
                {
                    data&&data.map((value, index)=>{
                        return(
                            <article id={value.id_barang} className="productbox">
                                <div className="content">
                                    <div className="image">
                                        <img className="lazy" src={`https://garudakasir.garudacyber.co.id/image/${value.idtoko}/${value.gambar}`} />
                                    </div>
        
                                    <div className="detail">
                                        <h3><a href="#" rel="bookmark">{value.nama_barang}</a></h3>
                                        <div className="pricing">
                                            <span className="price">Rp { value.id_merek }</span>
                                        </div>
                                        <div>
                                            {
                                                dataelect[value.id_barang]&&dataelect[value.id_barang]>0?
                                                <div  style={{display: 'block', justifyContent:'space-between', flexDirection:'row'}}>
                                                    <button onClick={()=>{ minSelect(value.id_barang) }} type="button" style={{backgroundColor:'#b51419' }} data-qty-action="minus"><span style={{ color:'#fff' }}>-</span></button>
                                                    <input type="number" name="qty" style={{ width:'70px', textAlign:'center' }} value={dataelect[value.id_barang]} />
                                                    <button onClick={()=>{ addSelect(value.id_barang) }} type="button" style={{backgroundColor:'#4caf50'}} data-qty-action="plus"><span style={{ color:'#fff' }}>+</span></button>
                                                </div>:
                                                <button style={{ backgroundColor:'#4caf50' }} onClick={()=>{ addSelect(value.id_barang) }}><span style={{ color:'#fff' }}>Pilih</span></button>
                                            }
                                            
                                            {/*  */}
                                        </div>
                                    </div>
                                </div>
                            </article>
                        )
                    })
                }
                    <div style={{ backgroundColor:'#efeeee', height:'45px', alignItems:'center', justifyContent:'center', alignContent:'center', marginBottom:'10px' }}>
                        <button onClick={()=>{loadMore()}} style={{ backgroundColor: 'Transparent', backgroundRepeat:'no-repear', border: 'none', cursor:'pointer', overflow: 'hidden', outline:'none', width:'100%' }} type="button">Load More...</button>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor:'#e0e0e0', position:'fixed', zIndex:99, left:0, bottom:0, width:'100%', }}>
                <div class="wrapper">
                    <div class="basket">
                        <div class="basket-cart">
                            <i class="lni lni-shopping-basket"></i>
                            
                        </div>
                        <div class="basket-detail">
                            <div class="basket-items-label">
                                Total Belanja
                            </div>
                            <div style={{ fontWeight:'bold' }}>{dataKeranjang}</div>
                        </div>
                        
                        <div class="basket-next" style={{backgroundColor:'#b51419', height:40, marginTop:4, borderRadius:4}}>
                            <Link style={{backgroundColor:'#b51419', height:100, borderRadius:4}} to={link}><span style={{ color:'#fff', marginTop:10, height:50 }}>Lanjutkan</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        
    )
}

export default Item;