import React from "react";

const Error = () => {
    return (
        <div>
            Not Found 
        </div>
    )
}

export default Error;
